<template>
  <div class="container">
    <SkeletonAnimate
      :visible="preloader"
    />

    <template
      v-if="data"
    >
      <div class="container__control">
        <ClosePopUpButton
          class="black"
        />
      </div>

      <div class="container__content">
        <h2>
          {{ $t('components.ui.pages.pop-up.payment-information.payment-information') }}
        </h2>

        <p class="container__content__description">
          {{ $t('components.ui.pages.pop-up.payment-information.payment', { value: data.payment_data?.currency_slug }) }}
        </p>

        <div class="container__content__flex">
          <div class="container__content__flex__start">
            <div class="container__content__flex__start__row top">
              <div class="container__content__flex__start__row__left">
                <p class="little-text">
                  {{ $t('components.ui.pages.pop-up.payment-information.order') }}:
                </p>

                <p class="content">
                  ID: {{ data.payment_data?.order_id }}
                </p>
              </div>
            </div>

            <div class="container__content__flex__start__row top flex">
              <div class="container__content__flex__start__row__left">
                <p class="little-text">
                  {{ $t('components.ui.pages.pop-up.payment-information.address') }}:
                </p>

                <p class="content address">
                  {{ data.payment_data?.address }}
                </p>
              </div>

              <button
                class="copy"
                :class="{
                  active : isCopy
                }"
                @click="copy()"
              >
                <svg
                  v-if="!isCopy"
                  width="18" height="18"
                  style="min-width: 18px; min-height: 18px"
                  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1331_24973)">
                    <path d="M15.4522 17.4287H6.48731C5.39677 17.4287 4.50977 16.5417 4.50977 15.4512V6.48633C4.50977 5.3958 5.39677 4.50879 6.48731 4.50879H15.4522C16.5427 4.50879 17.4297 5.3958 17.4297 6.48633V15.4512C17.4297 16.5417 16.5427 17.4287 15.4522 17.4287ZM6.48731 5.82715C6.12383 5.82715 5.82813 6.12285 5.82813 6.48633V15.4512C5.82813 15.8146 6.12383 16.1104 6.48731 16.1104H15.4522C15.8156 16.1104 16.1113 15.8146 16.1113 15.4512V6.48633C16.1113 6.12285 15.8156 5.82715 15.4522 5.82715H6.48731ZM3.19141 12.1553H2.53223C2.16875 12.1553 1.87305 11.8596 1.87305 11.4961V2.53125C1.87305 2.16777 2.16875 1.87207 2.53223 1.87207H11.4971C11.8605 1.87207 12.1563 2.16777 12.1563 2.53125V3.15747H13.4746V2.53125C13.4746 1.44072 12.5876 0.553711 11.4971 0.553711H2.53223C1.4417 0.553711 0.554688 1.44072 0.554688 2.53125V11.4961C0.554688 12.5866 1.4417 13.4736 2.53223 13.4736H3.19141V12.1553Z" fill="#202539"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1331_24973">
                      <rect width="18" height="18" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>

                <svg v-else width="20" height="15" style="min-width: 20px; min-height: 15px" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 6.28571L7.81818 12L18 2" stroke="#D3FF53" stroke-width="3" stroke-linecap="round"/>
                </svg>
              </button>
            </div>

            <div class="container__content__flex__start__row bottom">
              <div class="flex">
                <p class="small-text">
                  {{ $t('components.ui.pages.pop-up.payment-information.to-pay') }}:
                </p>

                <p class="content">
                  {{
                    $format.wallet(
                      data.payment_data?.currency_slug === 'USDT' || data.payment_data?.currency_slug === 'USDC' ? parseInt(data.payment_data.currency_amount)
                        : data.payment_data?.currency_amount, ',')
                  }} {{ data.payment_data?.currency_slug }}
                </p>
              </div>

              <button
                class="copy"
                :class="{
                  active : isCopyVal
                }"
                @click="
                  copyValue(
                    data.payment_data?.currency_slug === 'USDT'
                      || data.payment_data?.currency_slug === 'USDC' ? parseInt(data.payment_data.currency_amount)
                        : data.payment_data?.currency_amount
                  )
                "
              >
                <svg
                  v-if="!isCopyVal"
                  width="18" height="18"
                  style="min-width: 18px; min-height: 18px"
                  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1331_24973)">
                    <path d="M15.4522 17.4287H6.48731C5.39677 17.4287 4.50977 16.5417 4.50977 15.4512V6.48633C4.50977 5.3958 5.39677 4.50879 6.48731 4.50879H15.4522C16.5427 4.50879 17.4297 5.3958 17.4297 6.48633V15.4512C17.4297 16.5417 16.5427 17.4287 15.4522 17.4287ZM6.48731 5.82715C6.12383 5.82715 5.82813 6.12285 5.82813 6.48633V15.4512C5.82813 15.8146 6.12383 16.1104 6.48731 16.1104H15.4522C15.8156 16.1104 16.1113 15.8146 16.1113 15.4512V6.48633C16.1113 6.12285 15.8156 5.82715 15.4522 5.82715H6.48731ZM3.19141 12.1553H2.53223C2.16875 12.1553 1.87305 11.8596 1.87305 11.4961V2.53125C1.87305 2.16777 2.16875 1.87207 2.53223 1.87207H11.4971C11.8605 1.87207 12.1563 2.16777 12.1563 2.53125V3.15747H13.4746V2.53125C13.4746 1.44072 12.5876 0.553711 11.4971 0.553711H2.53223C1.4417 0.553711 0.554688 1.44072 0.554688 2.53125V11.4961C0.554688 12.5866 1.4417 13.4736 2.53223 13.4736H3.19141V12.1553Z" fill="#202539"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1331_24973">
                      <rect width="18" height="18" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>

                <svg v-else width="20" height="15" style="min-width: 20px; min-height: 15px" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 6.28571L7.81818 12L18 2" stroke="#D3FF53" stroke-width="3" stroke-linecap="round"/>
                </svg>
              </button>
            </div>

            <div class="container__content__flex__start__row bottom">
              <p class="small-text">
                {{ $t('components.ui.pages.pop-up.payment-information.received') }}:
              </p>

              <p class="content">
                {{
                  $format.wallet(
                    data.payment_data?.currency_slug === 'USDT' || data.payment_data?.currency_slug === 'USDC' ? parseInt(data.received)
                      : data.received, ',')
                }} {{ data.payment_data?.currency_slug }}
              </p>
            </div>

            <div class="container__content__flex__start__info">
              <div class="container__content__flex__start__info__info">
                <div class="container__content__flex__start__info__info__icon">
                  <svg
                    width="4" height="14"
                    style="min-width: 4px; max-height: 14px;"
                    viewBox="0 0 4 14" fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0.959219 9.3403L0.799219 0H3.23922L3.09922 9.3403H0.959219ZM0.699219 11.1791H3.29922V14H0.699219V11.1791Z" fill="#202539"/>
                  </svg>
                </div>

                {{ $t('components.ui.pages.pop-up.payment-information.important-information') }}
              </div>

              <ul>
                <li>
                  {{ $t('components.ui.pages.pop-up.payment-information.send') }} <span>{{ data.payment_data?.currency_slug }}</span>
                </li>

                <li>
                  {{ $t('components.ui.pages.pop-up.payment-information.verify') }}: <span>{{ data.payment_data?.currency_name }}</span>
                </li>

                <li>
                  {{ $t('components.ui.pages.pop-up.payment-information.make') }}
                </li>
              </ul>
            </div>
          </div>

          <div class="container__content__flex__end">
            <div class="container__content__flex__end__card">
              <p class="small-text">
                {{ $t('components.ui.pages.pop-up.payment-information.network') }}
              </p>

              <p class="token">
                {{ data.payment_data?.currency_name }}
              </p>

              <QrCode
                :value="data.payment_data?.address"
              />
            </div>

            <p
              v-if="_countDown && _countDown !== 'Invalid Date'"
              class="small-text timer"
            >
              {{ $t('components.ui.pages.pop-up.payment-information.period') }}:

              <br>

              <span>
                {{ _countDown }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SkeletonAnimate from '@/ui/preloader/SkeletonAnimatePreloader.vue'
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import QrCode from '@/ui/QrCode.vue'

export default {
  components: {
    SkeletonAnimate,
    ClosePopUpButton,
    QrCode
  },
  data () {
    return {
      preloader: true,
      timer: null,
      isCopy: false,
      isCopyVal: false,
      countOut: 0,
      data: null,
      refresh: null
    }
  },
  computed: {
    _getContainerData () {
      return this.$store.state.popUp.active.id
    },
    _countDown () {
      const out = this.countOut
      const nowDate = new Date()
      const endDate = new Date(this.data.payment_data?.expiresAt)

      const time = Date.parse(endDate) - (Date.parse(nowDate) + out)

      if (time <= 0) {
        return false
      }

      const parseTime = new Date(time).toString()

      const _optionsTime = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }

      return new Date(parseTime).toLocaleTimeString('ru', _optionsTime)
    }
  },
  mounted () {
    this.fetch()

    this.refresh = setInterval(() => {
      if (this._countDown && this._countDown !== 'Invalid Date') {
        this.fetch()
      } else {
        clearInterval(this.refresh)
      }
    }, 60000)
  },
  beforeUnmount () {
    clearInterval(this.timer)
    clearInterval(this.refresh)
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          `/private/checkout/payment-data/${this._getContainerData}`)

        if (resp.status === 200) {
          this.data = resp.data.data
          this.startTimer()
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.preloader = false
      }
    },
    startTimer () {
      this.timer = setInterval(() => {
        this.countOut += 1
      }, 1000)
    },
    copy () {
      this.isCopy = true
      var copyTextarea = document.createElement('textarea')
      copyTextarea.style.position = 'fixed'
      copyTextarea.style.height = '1px'
      copyTextarea.style.width = '1px'
      copyTextarea.style.opacity = '0'
      copyTextarea.textContent = this.data.payment_data.address

      document.body.appendChild(copyTextarea)
      copyTextarea.select()
      document.execCommand('copy')
      document.body.removeChild(copyTextarea)

      setTimeout(() => {
        this.isCopy = false
      }, 4000)
    },
    copyValue (val) {
      this.isCopyVal = true
      var copyTextarea = document.createElement('textarea')
      copyTextarea.style.position = 'fixed'
      copyTextarea.style.height = '1px'
      copyTextarea.style.width = '1px'
      copyTextarea.style.opacity = '0'
      copyTextarea.textContent = val

      document.body.appendChild(copyTextarea)
      copyTextarea.select()
      document.execCommand('copy')
      document.body.removeChild(copyTextarea)

      setTimeout(() => {
        this.isCopyVal = false
      }, 4000)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  min-height: 570px;
  width: 100%;
  max-width: 980px;
  padding: 10px;
  background: white;
  border-radius: 15px;
  overflow: hidden;

  .container__control {
    display: flex;
    justify-content: flex-end;
  }

  .container__content {
    padding: 0 30px 30px;

    h2 {
      margin-bottom: 10px;
      line-height: 100%;
      color: #202539;
      font-family: SemiBold;
    }

    p.container__content__description {
      font-size: 16px;
      line-height: 100%;
      color: #787C8D;
      font-family: Medium;
    }

    .container__content__flex {
      margin-top: 40px;
      display: flex;

      .container__content__flex__start {
        width: 100%;
        margin-right: 40px;

        .container__content__flex__start__row {
          height: auto;
          min-height: 60px;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          padding-top: 10px !important;
          border-bottom: 1px solid rgba(156, 163, 184, .6);

          &.top {
            padding: 0 0 10px;
          }

          &.bottom {
            padding: 0 0 14px;
            align-items: center;

            p.small-text {
              line-height: 100%;
              color: #787C8D;
              font-family: Medium;
            }

            p.content {
              font-size: 18px;
              line-height: 100%;
              color: #202539;
              font-family: SemiBold;
            }

            .flex {
              width: 100%;
              display: flex;
              justify-content: space-between;
            }
          }

          .container__content__flex__start__row__left {
            font-family: Medium;
            margin-right: 15px;
            overflow: hidden;

            p.little-text {
              margin-bottom: 6px;
              line-height: 100%;
              color: #787C8D;
              font-family: inherit;
            }

            p.content {
              width: 300px;
              max-width: 300px;
              font-size: 14px;
              line-height: 100%;
              color: #202539;
              font-family: inherit;
              overflow-wrap: break-word;
            }
          }

          button.copy {
            height: 36px;
            width: 36px;
            min-height: 36px;
            min-width: 36px;
            margin-left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: rgba(47, 87, 233, .2);

            &.active {
              background: rgb(var(--color-dark));
            }
          }
        }

        .container__content__flex__start__info {
          width: 100%;
          margin-top: 40px;
          padding: 15px;
          background: rgba(47, 87, 233, .1);
          border-radius: 10px;

          .container__content__flex__start__info__info {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 100%;
            color: #202539;
            font-family: SemiBold;

            .container__content__flex__start__info__info__icon {
              height: 28px;
              width: 28px;
              margin-right: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background: #D3FF53;
            }
          }

          ul {
            margin: 15px 0 0;
            padding-left: 25px !important;

            li {
              font-size: 16px;
              line-height: 150%;
              color: #202539;
              font-family: Medium;

              span {
                font-family: SemiBold;
              }
            }
          }
        }
      }

      .container__content__flex__end {
        width: 280px;

        .container__content__flex__end__card {
          padding: 20px 37px 37px;
          background: #FFFFFF;
          box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
          border-radius: 15px;

          p {
            line-height: 100%;
            text-align: center;

            &.small-text {
              color: #787C8D;
              font-family: Medium;
            }

            &.token {
              margin: 10px 0 20px;
              font-size: 18px;
              color: #202539;
              font-family: SemiBold;
            }
          }
        }

        p.timer {
          margin-top: 20px;
          text-align: center;
          line-height: 100%;
          color: #787C8D;
          font-family: Medium;
          white-space: nowrap;

          span {
            margin-top: 3px;
            display: block;
            line-height: 100%;
            color: #2F57E9;
            font-family: Medium;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    .container__content {
      padding: 0 5px 30px;

      .container__content__flex {
        flex-direction: column-reverse;
        align-items: center;

        .container__content__flex__start {
          margin-right: 0;

          .container__content__flex__start__row {
            height: auto;
            min-height: 60px;

            .container__content__flex__start__row__left {
              p.content {
                width: 100%;
                max-width: calc(100vw - 100px);
                font-size: 14px;
                line-height: 100%;
                color: #202539;
                font-family: inherit;
                overflow-wrap: break-word;
              }
            }
          }
        }

        .container__content__flex__end {
          margin-bottom: 40px;

          .container__content__flex__end__card {
            margin: 0 auto;
          }
        }
      }
    }
  }
}
</style>
